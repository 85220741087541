import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '~components/layouts/Default';
import Container from '~components/common/Container';
import GridFour from '~components/common/GridFour';
import H1 from '~components/typography/H1';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';
import Button from '~components/common/Button';
import GridTwo from '~components/common/GridTwo';
import SvgCard from '~components/common/SvgCard';
import PricingCard from '~components/common/PricingCard';
import parse from 'html-react-parser';
import FaqItem from '~components/common/FaqItem';
import Grid from '~components/common/Grid';

const svgs = {
  certificate: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-brand-green">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="15" cy="15" r="3" />
      <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
      <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
      <line x1="6" y1="9" x2="18" y2="9" />
      <line x1="6" y1="12" x2="9" y2="12" />
      <line x1="6" y1="15" x2="8" y2="15" />
    </svg>
  ),
  money: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-brand-green">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M17.2 7a6 7 0 1 0 0 10" />
      <path d="M13 10h-8m0 4h8" />
    </svg>
  ),
  mobile: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-brand-green">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="13" y="8" width="8" height="12" rx="1" />
      <path d="M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9" />
      <line x1="16" y1="9" x2="18" y2="9" />
    </svg>
  ),
  date: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-brand-green">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="4" y="5" width="16" height="16" rx="2" />
      <line x1="16" y1="3" x2="16" y2="7" />
      <line x1="8" y1="3" x2="8" y2="7" />
      <line x1="4" y1="11" x2="20" y2="11" />
      <rect x="8" y="15" width="2" height="2" />
    </svg>
  ),
  book: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-brand-green">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
      <line x1="13" y1="8" x2="15" y2="8" />
      <line x1="13" y1="12" x2="15" y2="12" />
    </svg>
  ),
  plan: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-brand-green">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M3 7h1.948c1.913 0 3.705 .933 4.802 2.5a5.861 5.861 0 0 0 4.802 2.5h6.448" />
      <path d="M3 17h1.95a5.854 5.854 0 0 0 4.798 -2.5a5.854 5.854 0 0 1 4.798 -2.5h5.454" />
      <path d="M18 15l3 -3l-3 -3" />
    </svg>
  ),
  knowledge: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-brand-green">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6" />
      <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4" />
    </svg>
  ),
  feedback: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-brand-green">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
      <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
    </svg>
  ),
  community: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-brand-green">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="5" r="2" />
      <circle cx="5" cy="19" r="2" />
      <circle cx="19" cy="19" r="2" />
      <circle cx="12" cy="14" r="3" />
      <line x1="12" y1="7" x2="12" y2="11" />
      <line x1="6.7" y1="17.8" x2="9.5" y2="15.8" />
      <line x1="17.3" y1="17.8" x2="14.5" y2="15.8" />
    </svg>
  ),
  extras: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="mx-auto text-brand-green">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
    </svg>
  )
};

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Kinetic Plus und Entspannungshelden" desc="Wir sind dein Partner für innovative online Gesundheitskurse.">
      <div className="bg-gray-50">
        <div className="overflow-hidden">
          <Container className="relative " dotsRight>
            <GridTwo itemsCenter>
              <div className="sm:text-center md:mx-auto md:max-w-2xl lg:text-left">
                <H1 className="mb-5">
                  <span className="mt-1 block text-4xl font-extrabold text-brand-green sm:text-5xl xl:text-6xl">Wir sind dein Partner für innovative online Gesundheitskurse.</span>
                </H1>
                <P className="mb-5 mt-3 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  Erlerne innerhalb von 8 Wochen das Autogene Training bequem von zu Hause aus. Durch unsere Zertifizierung übernimmt deine Krankenkasse zwischen ca. 75€ – 150€ der Kosten
                </P>
                <P className="mb-5 mt-3 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">Zertifiziert nach § 20 Abs. 4 Nr. 1 SGB V Zentrale Prüfstelle Prävention</P>
                <Button text="Zum Kurs" className="w-full" anchor="#kurs" />
              </div>
              <div>
                <StaticImage src="../media/pages/kinetic-plus/kinetic-plus_header.png" alt="Erstattung Krankenkasse" />
              </div>
            </GridTwo>
          </Container>
        </div>

        {/* <Container>
          <div className="mb-8 text-center">
            <H2>Gesund. Geprüft. Gefördert.</H2>
            <P>Online-Gesundheitskurse waren noch nie so einfach</P>
          </div>
          <GridFour itemsStart>
            <SvgCard title="Zertifiziert" text="Zertifizierung durch die Zentrale Prüfstelle Prävention." image={svgs.certificate} />
            <SvgCard title="Bezuschusst" text="Deine Krankenkasse zahlt 75 € - 149 € der Kursgebühr!" image={svgs.money} />
            <SvgCard title="Benutzerfreundlich" text="Einwandfreie Computer, Tablet und Smartphone Nutzung" image={svgs.mobile} />
            <SvgCard title="Unabhängig" text="Du entscheidest wann, wo und wie oft du entspannst!" image={svgs.date} />
          </GridFour>
        </Container>

        <Container>
          <div className="mb-8 text-center">
            <H2>Schritt-Für-Schritt</H2>
          </div>
          <GridFour itemsStart>
            <SvgCard title="Methode" text="Informiere dich auf der Seite über das Angebot." image={<div className="mx-auto mb-3 flex h-16 w-16 items-center justify-center rounded-full bg-brand p-3 text-xl font-bold text-white">1</div>} />
            <SvgCard
              title="Information"
              text="Lese dir den Informationstext aufmerksam durch."
              image={<div className="mx-auto mb-3 flex h-16 w-16 items-center justify-center rounded-full bg-brand-green p-3 text-xl font-bold text-white">2</div>}
            />
            <SvgCard
              title="Bezahlung"
              text="Du wirst zu unserem Partner elopage weitergeleitet. Elopage ist unser Partner des Vertrauens. Made in Germany - 100% Sicherheit deiner Daten. Dir stehen sämtliche Zahlungsarten zur Verfügung."
              image={<div className="mx-auto mb-3 flex h-16 w-16 items-center justify-center rounded-full bg-brand-green p-3 text-xl font-bold text-white">3</div>}
            />
            <SvgCard
              title="Zuschuss"
              text="Du erhältst eine Bestätigungsmail und kannst sofort loslegen. Du durchläufst deinen Kurs und erhältst anschließend deine Teilnahmebestätigung. Diese reichst du bei deiner Krankenkasse ein und erhältst so deinen Zuschuss."
              image={<div className="mx-auto mb-3 flex h-16 w-16 items-center justify-center rounded-full bg-brand-green p-3 text-xl font-bold text-white">4</div>}
            />
          </GridFour>
        </Container> */}

        <Container>
          <Grid cols={2} id="kurs">
            <PricingCard
              title={data.autogenesTraining.title}
              desc={data.autogenesTraining.desc}
              price={169}
              priceFrequenz={data.autogenesTraining.frequenz}
              features={data.autogenesTraining.features}
              link="https://elopage.com/s/entspannungshelden/ruhe-und-entspannung-autogenes-training/payment"
            />
            <PricingCard
              title={data.progressiveMuskelentspannung.title}
              desc={data.progressiveMuskelentspannung.desc}
              price={169}
              priceFrequenz={data.progressiveMuskelentspannung.frequenz}
              features={data.progressiveMuskelentspannung.features}
              link="https://elopage.com/s/entspannungshelden/progressive-Muskelentspannung-onlinekurs/payment"
            />
          </Grid>
        </Container>
        <div className="overflow-hidden pb-16">
          <Container className="relative " dotsRight dotsLeft>
            <div className="mb-8 text-center">
              <H1>FAQ</H1>
              <P className="mb-5">
                <a href={`mailto:${data.site.siteMetadata.contact.mail}`}>Falls deine Frage nicht dabei ist, schreibe gerne an - hallo@entspannungshelden.de</a>
              </P>
            </div>
            <dl className="mx-auto max-w-2xl">
              {data.allFaq.nodes.map((faq) => {
                return <FaqItem title={faq.frage} text={parse(faq.text)} key={faq.id} />;
              })}
            </dl>
            <div className="mt-8 text-center">
              <Button text="Noch fragen?" to="/kontakt" />
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    allFaq {
      nodes {
        text
        frage
        id
      }
    }
    site {
      siteMetadata {
        contact {
          mail
        }
      }
    }
    autogenesTraining: kurse(slug_slug: { eq: "autogenes-training-onlinekurs" }) {
      price
      title
      link
      frequenz
      desc
      features {
        value
        field {
          label
        }
      }
    }
    progressiveMuskelentspannung: kurse(slug_slug: { eq: "progressive-muskelentspannung-onlinekurs" }) {
      price
      title
      link
      frequenz
      desc
      features {
        value
        field {
          label
        }
      }
    }
  }
`;
